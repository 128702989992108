import { render, staticRenderFns } from "./Landing.vue?vue&type=template&id=3f5407da&scoped=true&"
import script from "./Landing.vue?vue&type=script&lang=ts&"
export * from "./Landing.vue?vue&type=script&lang=ts&"
import style0 from "./Landing.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Landing.vue?vue&type=style&index=1&id=3f5407da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f5407da",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VParallax } from 'vuetify/lib/components/VParallax';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VAppBar,VBtn,VCard,VCardText,VCardTitle,VChip,VChipGroup,VCol,VDialog,VDivider,VIcon,VImg,VParallax,VRow,VTab,VTabItem,VTabs,VTabsSlider,VTimeline,VTimelineItem})
